<template>
  <div class="lists-wrapper__lists">
    <div class="grid-wrapper">
      <div class="col col-12">
        <div class="grid-info flex-center">
          <span class="grid-heading">
            <h1>{{ $t('Salary report') }}</h1>
            <span class="entries">{{ NumberFormat(list.entries) }} {{ $t(`entr${list.entries == 1 ? 'y' : 'ies'}`) }}</span>
          </span>
          <div class="grid-actions">
            <div class="grid-date">
              <p class="grid-heading" style="color: inherit;">{{ $t('Start date') }}</p>
              <div class="date-range flex-row">
                <div class="input">
                  <VueCtkDateTimePicker id="list-period-date-range-start" @input="SearchDate({start: $event})" :value="list.date.range.start" label="" hint="" :locale="$i18n.locale.split('_')[0].replace(/no/, 'nb')" formatted="ddd, MMM D, YYYY, HH:mm" format="YYYY-MM-DD HH:mm:ss" :first-day-of-week="1" input-size="sm" :range="false" :no-shortcuts="true" :no-button="false" :auto-close="false" />
                </div>
              </div>
            </div>
            <div class="grid-date" style="margin-left: 20px;">
              <p class="grid-heading" style="color: inherit;">{{ $t('End date') }}</p>
              <div class="date-range flex-row">
                <div class="input">
                  <VueCtkDateTimePicker id="list-period-date-range-end" @input="SearchDate({end: $event})" :value="list.date.range.end" label="" hint="" :locale="$i18n.locale.split('_')[0].replace(/no/, 'nb')" formatted="ddd, MMM D, YYYY, HH:mm" format="YYYY-MM-DD HH:mm:ss" :first-day-of-week="1" input-size="sm" :range="false" :no-shortcuts="true" :no-button="false" :auto-close="false" />
                </div>
              </div>
            </div>
            <a class="button" @click.prevent="GetList" href="">{{ $t('Apply') }}</a>
            <div class="select period flex-column">
              <span class="label-text">{{ $t('Salary period') }}</span>
              <v-select name="period" @input="OnPeriodSelection" v-model="list.period.selected" :options="list.period.options" :placeholder="$t('Salary period')" :clearable="false">
                <!--
                <template slot="option" slot-scope="option">
                  {{ option.label.split(' ')[0] + ' ' + $t(option.label.split(' ').slice(-1)[0]) }}
                </template>
                -->
              </v-select>
            </div>
          </div>
        </div>
        <div class="simple-list">
          <ul class="simple-list__head">
            <li class="list-col list-cell user-name">{{ $t('Name') }}</li>
            <li class="list-col list-cell pay-type">{{ $t('Salary rate') }}</li>
            <li class="list-col list-cell punch-clock">{{ $t('Punch clock') }}</li>
            <li class="list-col list-cell punch-type">{{ $t('Punch type') }}</li>
            <li class="list-col list-cell punch-time">{{ $t('Punch time') }}</li>
            <li class="list-col list-cell order-count">{{ $t('Orders') }}</li>
            <li class="list-col list-cell salary-type">{{ $t('Salary type') }}</li>
            <li class="list-col list-cell salary-total">{{ $t('Salary total') }}</li>
          </ul>
          <ul class="simple-list__body selectable odd-even">
            <li class="padding-block" :data-id="item.user_id" :key="item.user_id" v-for="item in list.items" @mouseenter="Hover" @mouseleave="Hover" @click="Select">
              <ul class="list-row head">
                <li class="list-col list-cell user-name capitalize">{{ item.user_name }}</li>
                <li class="list-col list-cell pay-type twoinone">
                  <span class="twoinone-col">
                    <div class="twoinone-row">
                      <b>{{ $t('Hourly rate') }}</b>:
                    </div>
                    <div class="twoinone-row">
                      <b>{{ $t('Commission') }}</b>:
                    </div>
                    <div class="twoinone-row">
                      <b>{{ $t('Guaranteed salary') }}</b>:
                    </div>
                  </span>
                  <span class="twoinone-col">
                    <div class="twoinone-row">
                      Kr. {{ CurrencyFormat(item.hourly_pay) }}
                    </div>
                    <div class="twoinone-row">
                      Kr. {{ CurrencyFormat(item.commission_pay) }}
                    </div>
                    <div class="twoinone-row">
                      Kr. {{ CurrencyFormat(item.hourly_guarantee_salary) }}
                    </div>
                  </span>
                </li>
                <li class="list-col list-cell punch-clock">{{ NumberFormat(item.punch_clock.length - (item.unaccounted_punch_order_pack ? 1 : 0)) }} {{ $t('punches') }}</li>
                <li class="list-col list-cell punch-type twoinone">
                  <span class="twoinone-col">
                    <div class="twoinone-row">
                      <b>{{ $t('Hourly rate') }}</b>:
                    </div>
                    <div class="twoinone-row">
                      <b>{{ $t('Commission') }}</b>:
                    </div>
                  </span>
                  <span class="twoinone-col">
                    <div class="twoinone-row">
                      {{ NumberFormat(item.hourly_count) }}
                    </div>
                    <div class="twoinone-row">
                      {{ NumberFormat(item.commission_count) }}
                    </div>
                  </span>
                </li>
                <li class="list-col list-cell punch-time twoinone">
                  <span class="twoinone-col">
                    <div class="twoinone-row">
                      <b>{{ $t('Hourly rate') }}</b>:
                    </div>
                    <div class="twoinone-row">
                      <b>{{ $t('Commission') }}</b>:
                    </div>
                  </span>
                  <span class="twoinone-col">
                    <div class="twoinone-row">
                      {{ TimeFormat(item.hourly_time) }}
                    </div>
                    <div class="twoinone-row">
                      {{ TimeFormat(item.commission_time /*item.order_pack_time*/) }}
                    </div>
                  </span>
                </li>
                <li class="list-col list-cell order-count">{{ NumberFormat(item.order_pack.length) }} {{ $t('parcels') }}</li>
                <li class="list-col list-cell salary-type twoinone">
                  <span class="twoinone-col">
                    <div class="twoinone-row">
                      <b>{{ $t('Hourly rate') }}</b>:
                    </div>
                    <div class="twoinone-row">
                      <b>{{ $t('Commission') }}</b>:
                    </div>
                    <div class="twoinone-row">
                      <b>{{ $t('Guaranteed salary') }}</b>:
                    </div>
                  </span>
                  <span class="twoinone-col">
                    <div class="twoinone-row">
                      Kr. {{ CurrencyFormat(item.hourly_salary) }}
                    </div>
                    <div class="twoinone-row">
                      Kr. {{ CurrencyFormat(item.commission_salary) }}
                    </div>
                    <div class="twoinone-row">
                      Kr. {{ CurrencyFormat(item.guaranteed_salary) }}
                    </div>
                  </span>
                </li>
                <li class="list-col list-cell salary-total">Kr. {{ CurrencyFormat(item.salary_total) }}</li>
              </ul>
            </li>
          </ul>
        </div>
        <div class="grid-footer footer-content">
          <div class="button-container">
            <div class="entries">{{ selected.length || list.entries }} / {{ list.entries }}</div>
            <button class="button green" @click.prevent="DownloadCsvReport" :disabled="!list.entries">Download CSV</button>
            <button class="button" @click.prevent="GetWorkReport" :disabled="!(selected.length ? Object.values(this.list.items.filter(item => selected.includes(item.user_id))) : list.items).filter(item => item.punch_clock.length).length">{{ $t('View details') }}</button>
          </div>
        </div>
      </div>

      <Modal modal="detail" :value="modal.detail.open" :title="DateFormat(this.params.start_date) + '  -  ' + DateFormat(this.params.end_date)">
        <div id="salary-detail-view">
          <div class="modal-header"></div>
          <div class="modal-content">
            <div class="list-wrapper scrollable">
              <div class="multi-dimensional-list">
                <ul class="multi-dimensional-list__head">
                  <li class="list-col list-cell detail-user-name">{{ $t('Name') }}</li>
                  <li class="list-col list-cell detail-punch-in">{{ $t('Punched in') }}</li>
                  <li class="list-col list-cell detail-punch-out">{{ $t('Punched out') }}</li>
                  <li class="list-col list-cell detail-punch-time">{{ $t('Punch time') }}</li>
                  <li class="list-col list-cell detail-order-count">{{ $t('Orders') }}</li>
                  <li class="list-col list-cell detail-salary-total">{{ $t('Salary') }}</li>
                </ul>
                <ul class="multi-dimensional-list__body odd-even">
                  <li :data-id="item.user_id" :key="item.user_id" v-for="item in modal.detail.list.selected">
                    <ul class="list-row head" :class="{toggle: modal.detail.list.selected.length > 1, collapsed: modal.detail.list.selected.length > 1}" :style="{zIndex: modal.detail.list.selected.length > 1 ? '' : '2'}" @click="ToggleListItem">
                      <li class="list-col list-cell detail-user-name capitalize">{{ item.user_name }}</li>
                      <li class="list-col list-cell detail-punch-in">{{ item.punch_clock.length ? DateFormat(item.punch_clock.slice(item.unaccounted_punch_order_pack ? -2 : -1)[0].start_date) : '' }}</li>
                      <li class="list-col list-cell detail-punch-out">{{ item.punch_clock.length ? DateFormat(item.punch_clock[0].end_date) : '' }}</li>
                      <li class="list-col list-cell detail-punch-time">{{ TimeFormat(item.hourly_time + item.commission_time) }}</li>
                      <li class="list-col list-cell detail-order-count">{{ NumberFormat(item.order_pack.length) }} {{ $t('parcels') }}</li>
                      <li class="list-col list-cell detail-salary-total">Kr. {{ CurrencyFormat(item.salary_total) }}</li>
                    </ul>
                    <ul class="list-row body">
                      <li class="list-col" :data-id="data.id" :key="data.id" v-for="data in item.punch_clock">
                        <ul class="list-row nested">
                          <li class="list-col list-cell nested detail-user-name">
                            <span class="detail-pay-type">{{ $t(Capitalize(data.type + (data.type == 'hourly' ? ' rate' : ''))) }}</span>
                            <span v-if="data.type == 'guaranteed salary'" class="detail-pay-rate">Kr. {{ CurrencyFormat(item.hourly_guarantee_salary) }}</span>
                            <span v-else class="detail-pay-rate" :class="{hourly: data.type == 'hourly'}">Kr. {{ CurrencyFormat(item[data.type + '_pay']) }}</span>
                          </li>
                          <li class="list-col list-cell nested detail-punch-in">{{ data.start_date ? DateFormat(data.start_date) : '' }}</li>
                          <li class="list-col list-cell nested detail-punch-out">{{ data.end_date ? DateFormat(data.end_date) : '' }}</li>
                          <li class="list-col list-cell nested detail-punch-time">{{ data.end_date ? TimeFormat(data.total_seconds) : '' }}</li>
                          <li class="list-col list-cell nested detail-order-count">{{ NumberFormat(data.order_pack.length) }} {{ $t('parcels') }}</li>
                          <li class="list-col list-cell nested detail-salary-total">Kr. {{ CurrencyFormat(data.salary_total) }}</li>
                        </ul>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="modal-footer footer-content">
            <div class="button-container">
              <div class="entries">{{ modal.detail.list.selected.filter(item => item.punch_clock.length).length }} / {{ modal.detail.list.entries }}</div>
              <button class="button green" @click.prevent="DownloadCsvReport" :disabled="!modal.detail.list.selected.length">Download CSV</button>
            </div>
          </div>
        </div>
      </Modal>

      <div class="loading" v-if="loading">
        <div class="loading-animation">
          <div class="loading-element">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
      </div>
    </div>
    
  </div>
</template>

<script>
  import { json2csvAsync } from 'json-2-csv';
  // https://github.com/mrodrig/json-2-csv/wiki/json2csv-Documentation

  import { FormElementsBehaviour }  from '@/mixins/FormElementsBehaviour';
  import { TableElementsBehaviour } from '@/mixins/TableElementsBehaviour';
  import { BPA }                    from '@/helpers/BPA';
  import { Tool }                   from '@/helpers/Tool';
  import Modal                      from '@/components/snippets/Modal';

  export default {
    name: 'SalaryPayList',
    mixins: [BPA, FormElementsBehaviour, TableElementsBehaviour],
    components: {Modal},
    data() {
      return {
        loading: false,
        data: {},
        user: null,
        prev: null,
        group: [],
        params: {},
        selected: [],
        ctrl_key: false,
        shift_key: false,
        event: ['keydown', 'keyup'],
        list: {
          entries: 0,
          items: [],
          period: {
            options: [],
            default: {},
            selected: {},
            value: ''
          },
          date: {
            disabled: false,
            range: {
              start: null,
              end: null
            }
          }
        },
        modal: {
          detail: {
            disabled: true,
            open: false,
            title: null,
            params: {},
            data: {},
            tabs: [
              'work_report',
              'punch_clock',
              'punch_order'
            ],
            tab: {
              active: 'work_report',
              work_report: {},
              punch_clock: {},
              punch_order: {}
            },
            list: {
              selected: [],
              animate: null,
              entries: 0,
              items: [],
              toggles: [],
              observers: []
            }
          }
        }
      }
    },
    created() {
      this.$eventHub.$on('CloseModal', (modal_name) => {
        if (this.modal[modal_name]) this.modal[modal_name].open = false;
      });
      const createSalaryPeriodOptions = () => {
        let date = new Date();
        //let month = date.getMonth();
        let start = new Date();
        let end = new Date();
        let periods_total = 11;
        const periods = [];
        start.setDate(16);
        end.setDate(15);
        start.setHours(0, 0, 0);
        end.setHours(23, 59, 0);
        let periods_this_year = periods_total - date.getMonth();
        let periods_last_year = periods_total - periods_this_year;
        let index = 0;
        for (let i = periods_total; i >= periods_this_year; --i) {
          start.setMonth(index);
          end.setMonth(start.getMonth() + 1);
          const month_name = start.toLocaleString('en-GB', {month: 'long'});
          const year = start.getFullYear();
          periods.push({
            period: year + ', ' + month_name,
            start: this.DateToISO(start),
            end: this.DateToISO(end)
          });
          index++;
        }
        //console.log(JSON.parse(JSON.stringify(periods)))
        periods.reverse();
        index = 0;
        for (let i = periods_total; i > periods_last_year; --i) {
          start.setFullYear(date.getFullYear() - 1);
          end.setFullYear(date.getFullYear() - 1);
          start.setMonth(i < 0 ? 0 : i);
          end.setMonth(start.getMonth() + 1);
          const month_name = start.toLocaleString('en-GB', {month: 'long'});
          const year = start.getFullYear();
          periods.push({
            period: year + ', ' + month_name,
            start: this.DateToISO(start),
            end: this.DateToISO(end)
          });
          index++;
        }
        for (let i = 0; i < periods.length; i++) {
          let period = periods[i].period.split(', ');
          this.list.period.options.push({
            code: JSON.stringify(periods[i]),
            label: period[0] + ', ' + this.$t(period[1])
          });
          if (date > new Date(periods[i].start) && date < new Date(periods[i].end)) {
            this.list.period.selected = this.list.period.options[i];
            this.list.period.value = this.list.period.selected.label;
            this.list.date.range = {
              start: this.DateToISO(periods[i].start),
              end: this.DateToISO(periods[i].end)
            }
            //this.GetList();
          }
        }
      }
      this.MountKeyPressListener();
      createSalaryPeriodOptions();
    },
    destroyed() {
      this.UnmountKeyPressListener();
      this.UnobserveIntersections();
    },
    computed: {
      keydown() {
        return (event) => {
          this.Ctrl(event);
          this.Shift(event);
        }
      },
      keyup() {
        return () => {
          this.Ctrl(false);
          this.Shift(false);
        }
      },
      locale() {
        return this.$i18n.locale.replace('_', '-');
      }
    },
    watch: {
      locale() {
        const options = JSON.parse(JSON.stringify(this.list.period.options));
        const selected = JSON.parse(JSON.stringify(this.list.period.selected));
        for (let i = 0; i < options.length; i++) {
          let option = JSON.parse(options[i].code);
          let select = JSON.parse(selected.code);
          let period = option.period.split(', ');
          period[1] = this.$t(period[1]);
          period = period.join(', ');
          options[i].label = period;
          if (option.start == select.start) {
            this.list.period.selected = options[i];
            this.list.period.value = this.list.period.selected.label;
          }
        }
        this.list.period.options = options;
      }
    },
    methods: {
      Ctrl(e) {
        this.ctrl_key = e && e.getModifierState('Control');
      },
      Shift(e) {
        this.shift_key = e && e.getModifierState('Shift');
      },
      MountKeyPressListener() {
        this.event.map(e => addEventListener(e, this[e]));
      },
      UnmountKeyPressListener() {
        this.event.map(e => removeEventListener(e, this[e]));
      },
      DateFormat(date) {
        if (date) return Tool.DateFormat(date);
      },
      DateToUTC(date) {
        if (date) return Tool.DateToUTC(date);
      },
      DateToISO(date, local_time_zone) {
        if (date) return Tool.DateToISO(date, local_time_zone);
      },
      TimeFormat(seconds = 0, all_parts = false) {
        let time = Tool.TimeFormat(seconds, all_parts).split(' ').map(x => x.replace(/\D/g, '') + this.$t(x.replace(/\d/g, ''))).join(' ');
        return time ? time : ('00' + this.$t('s'));
      },
      NumberFormat(number, locale) {
        return Tool.NumberFormat(number, locale);
      },
      CurrencyFormat(number, locale) {
        return Tool.CurrencyFormat(number, locale);
      },
      Capitalize(string) {
        return Tool.Capitalize(string);
      },
      TitleCase(string) {
        return Tool.TitleCase(string);
      },
      Empty(element) {
        if (!element) return;
        while (element.firstChild) {
          element.removeChild(element.firstChild);
        }
      },
      OnDateSelection() {
        this.list.period.selected = {code: '{}', label: ''};
        this.list.period.value = '';
      },
      async SearchDate(date) {
        let type = Object.keys(date)[0];
        let range = this.list.date.range;
        if (date[type] && !range[type]) {
          let time = (type == 'start' ? '00:00' : '23:59') + ':00';
          date[type] = date[type].split(' ')[0] + ' ' + time;
        }
        range[type] = date[type];
      },
      OnPeriodSelection(option) {
        if (option) {
          const date = JSON.parse(option.code);
          this.list.date.range = {
            start: this.DateToISO(date.start),
            end: this.DateToISO(date.end)
          }
          //this.GetList();
        }
      },
      PreSelectDateRange() {
        let start = new Date();
        const end = new Date();
        const date = new Date();
        let today = date.getDate();
        let month = date.getMonth() + 1;
        start.setDate(16); 
        end.setDate(15);
        if (today > start.getDate()) {
          start.setMonth(month - 1);
          if (today < end.getDate()) {
            start.setMonth(month - 2);
            end.setMonth(month - 1);
          }
        } else if (today < start.getDate()) {
          start.setMonth(month - 3);
          end.setMonth(month - 2);
          if (today > end.getDate()) {
            start.setMonth(month - 2);
            end.setMonth(month - 1);
          }
        } else {
          console.log('else', today)
        }
        this.list.date.range = {
          start: this.DateToISO(start),
          end: this.DateToISO(end)
        }
      },
      GetList() {
        const range = this.list.date.range;
        const params = {
          start_date: new Date(range.start),
          end_date: new Date(range.end)
        }
        if (range.start && range.end) {
          if (params.end_date > params.start_date) {
            params.start_date = this.DateToUTC(params.start_date);
            params.end_date = this.DateToUTC(params.end_date);
            if (JSON.stringify(params) == JSON.stringify(this.params)) return;
            this.loading = true;
            BPA.api.GetPayListInterval(params).then(response => {
              return BPA.api.response({response, return: 'json'});
            }).then(response => {
              this.loading = false;
              if (!response.ok || !response.result) return;
              let data = response.result || {};
              this.data = data;
              this.user = null;
              this.group = [];
              const items = [];
              this.Deselect(true);
              this.params = params;
              for (const user_id in data.result) {
                const result = data.result[user_id];
                const user = result.res_user;
                const punchCount = (type) => result.punch_clock.filter(punch => punch.type == type).length;
                const hourly_salary = user.hourly_pay * (result.punch_clock_hourly / 3600).toFixed(2);
                const commission_salary = user.commission_pay * result.order_pack.length;
                const guarantee_salary = user.hourly_guarantee_salary * (result.punch_clock_commission / 3600).toFixed(2);
                const punch_order_pack_missing = [];
                const punch_order_pack_found = [];
                const punch_order_pack = {};
                const item = {
                  user_id: user_id,
                  social_security: user.social_security_no,
                  user_name: user.full_name,
                  hourly_pay: user.hourly_pay,
                  commission_pay: user.commission_pay,
                  hourly_guarantee_salary: user.hourly_guarantee_salary,
                  punch_clock: result.punch_clock,
                  hourly_count: punchCount('hourly'),
                  commission_count: punchCount('commission'),
                  hourly_time: result.punch_clock_hourly,
                  commission_time: result.punch_clock_commission,
                  order_pack: result.order_pack,
                  order_pack_time: result.order_pack.reduce((a, b) => a + b.total_seconds, 0),
                  hourly_salary: hourly_salary,
                  commission_salary: commission_salary,
                  guaranteed_salary: guarantee_salary,
                  salary_total: hourly_salary + commission_salary + guarantee_salary,
                  unaccounted_punch_order_pack: false,
                  //company: data.res_company[user.res_company_id]
                }
                for (let punch of item.punch_clock) {
                  punch.order_pack = [];
                  if (punch.type == 'commission') {
                    for (let order of item.order_pack) {
                      if (punch.start_date <= order.start_date && punch.end_date >= order.end_date) {
                        punch_order_pack[order.increment_id] = order;
                        punch.order_pack.push(order);
                      }
                    }
                    punch.salary_total = item.commission_pay * punch.order_pack.length;
                            
                    let guarantee_salary = JSON.parse(JSON.stringify(punch))
                    
                    guarantee_salary.type = 'guaranteed salary'
                    guarantee_salary.salary_total = item.hourly_guarantee_salary * (punch.total_seconds / 3600).toFixed(2);

                    item.punch_clock.push(guarantee_salary)

                  } else if (punch.type == 'hourly') {
                    punch.salary_total = item.hourly_pay * (punch.total_seconds / 3600).toFixed(2);
                  }
                }
                for (let order of item.order_pack) {
                  if (!Object.keys(punch_order_pack).includes(order.increment_id)) {
                    punch_order_pack_missing.push(order);
                  } 
                }
                if (punch_order_pack_missing.length) {
                  //console.log(item.user_name, JSON.parse(JSON.stringify(punch_order_pack_missing)))
                  for (let order of punch_order_pack_missing) {
                    for (let punch of item.punch_clock) {
                      if (punch.type == 'commission') {
                        //console.log(JSON.parse(JSON.stringify(punch)))
                        let punch_date = new Date(punch.start_date);
                        let order_date = new Date(order.start_date);
                        punch_date.setHours(0, 0, 0, 0);
                        order_date.setHours(0, 0, 0, 0);
                        if (punch_date.getTime() == order_date.getTime()) {
                          if (punch.start_date == order.end_date || punch.end_date == order.start_date || (punch.start_date <= order.start_date && punch.end_date >= order.start_date)) {
                            punch_order_pack_found.push(order);
                            punch.order_pack.push(order);
                            punch.salary_total = item.commission_pay * punch.order_pack.length;
                            /*
                            console.log('FOUND:', JSON.parse(JSON.stringify(order)))
                            console.log('PUNCH:', JSON.parse(JSON.stringify(punch)))
                            */
                            break;
                          }
                          /*
                          console.log('MISSING:', JSON.parse(JSON.stringify(order)))
                          console.log('PUNCH:', JSON.parse(JSON.stringify(punch)))
                          */
                        }
                      }
                    }
                  }
                  //console.log(item.user_name + ', unaccounted order pack:', punch_order_pack_missing.length - punch_order_pack_found.length)
                  if (item.punch_clock.length && (punch_order_pack_missing.length - punch_order_pack_found.length)) {
                    item.unaccounted_punch_order_pack = true;
                    item.punch_clock.push({
                      type: 'commission',
                      start_date: null,
                      end_date: null,
                      total_seconds: 0,
                      order_pack: punch_order_pack_missing,
                      salary_total: item.commission_pay * punch_order_pack_missing.length
                    });
                  }
                }
                item.punch_clock.sort((a, b) => new Date(b.start_date) - new Date(a.start_date));
                if (item.punch_clock.length) items.push(item);
              }
              this.list.entries = Object.keys(data.result).length;
              this.list.items = this.Alphabetize(items);
            }).catch(e => e);
          }
        }
      },
      Hover(e) {
        e.target.classList.toggle('hover');
      },
      Alphabetize(list) {
        return list.sort((a, b) => {
          a = a.user_name.toUpperCase(); 
          b = b.user_name.toUpperCase();
          return a < b ? -1 : a > b ? 1 : 0;
        });
      },
      Select(e) {
        const item = e.target;
        const user_id = item.dataset.id;
        const remove = (id) => this.selected.filter(i => i != id);
        if (!this.ctrl_key && !this.shift_key) {
          /*
          this.Deselect(true);
          this.selected = [user_id];
          item.classList.add('selected');
          this.user = this.data.result[user_id].res_user;
          */
          const selected = item.classList.contains('selected');
          this.Deselect(true);
          this.user = !selected && this.data.result[user_id].res_user;
          item.classList[!selected ? 'add' : 'remove']('selected');
          this.selected = !selected ? [user_id] : [];
        } else {
          this.user = null;
          if (this.ctrl_key) {
            this.prev = null;
            const selected = Tool.Siblings(item).filter(e => e.classList.contains('selected'))[0];
            if (selected && !this.selected.includes(selected.dataset.id)) {
              this.selected.push(selected.dataset.id);
            }
            item.classList.toggle('selected');
            if (!item.classList.contains('selected')) {
              this.selected = remove(user_id);
            } else if (!this.selected.includes(user_id)) {
              this.selected.push(user_id);
            }
          } else { // this.shift_key
            const list = item.parentNode;
            const items = list ? Array.from(list.childNodes) : [];
            const indexOf = (item) => item && items.indexOf(item);
            const prev_index = indexOf(this.prev);
            const item_index = indexOf(item);
            const select = (item, bypass) => {
              if (!item) return;
              if (item != this.prev || bypass) {
                item.classList.toggle('selected');
              }
              if (item.classList.contains('selected')) {
                if (!this.selected.includes(item.dataset.id)) {
                  this.selected.push(item.dataset.id);
                }
              } else {
                this.selected = this.selected.filter(id => id != item.dataset.id);
              }
            }
            if (item_index > prev_index) { // next
              for (let i = prev_index; i <= item_index; i++) select(items[i]);
            } else if (item_index < prev_index) { // prev
              for (let i = prev_index; i >= item_index; i--) select(items[i]);
            } else { 
              select(item, true);
            }
          }
        }
        this.prev = item;
      },
      Deselect(item) {
        if (item === true) {
          document.querySelectorAll('li.selected').forEach(e => {
            e.classList.remove('selected');
          });
          this.user = null;
          this.prev = null;
          this.selected = [];
          return;
        }
        item.classList.remove('selected');
      },
      JSON(object) {
        return JSON.parse(JSON.stringify(object));
      },
      async GetSelected() {
        return await new Promise((resolve, reject) => {
          const data_result = Object.keys(this.data.result);
          const selected = this.selected.length ? this.selected : data_result.length ? data_result : [];
          if (selected.length == 1) this.user = this.list.items.find(item => item.user_id == selected[0]);
          selected.length ? resolve(selected) : (() => {
            this.$eventHub.$emit('ShowMessages', {
              message: 'No employees selected',
              type: 'error',
              hide: 2000
            });
            reject();
          })();
        });
      },
      GetWorkDoneReport() {
        this.loading = true;
        return new Promise((resolve, reject) => {
          BPA.api.GetWorkDoneReport(this.params).then(response => {
            return BPA.api.response({response, return: 'json'});
          }).then(response => {
            this.loading = false;
            if (!response.ok || !response.result) return reject();
            resolve(response.result);
          }).catch(reject);
        }).catch(e => e);
      },
      async GetWorkReport() {
        await this.GetSelected().then(this.OpenDetailViewModal);
        /*
        await this.GetSelected().then(selected => {
          this.modal.detail.title = this.user && this.user.user_name;
          if (JSON.stringify(this.modal.detail.params) != JSON.stringify(this.params)) {
            this.GetWorkDoneReport().then(data => {
              console.log(data)
              if (!data) return;
              const items = [];
              this.modal.detail.data = data;
              this.modal.detail.params = this.params;
              for (const user_id in data.report) {
                const report = data.report[user_id];
                const user = data.res_user_ids[user_id];
                const punch_clock = report.punch_clock;
                const no_punch_out = punch_clock.punch_clock_ids_not_punch_out;
                const punchClock = (id) => data.punch_clock_ids.filter(punch => punch.res_user_id == id);
                items.push({
                  user_id: user_id,
                  user_name: String(user.name + ' ' + user.surname).trim(),
                  punch_clock_count: punchClock(user_id).length,
                  hourly_count: punch_clock.hourly_count,
                  hourly_time: punch_clock.hourly_time,
                  commission_count: punch_clock.commission_count,
                  commission_time: punch_clock.commission_time,
                  no_punch_out: no_punch_out,
                  punch_clock: punchClock(user_id),
                  order_count: report.commission.count,
                  order: report.commission.order_overview_ids
                });
              }
              this.modal.detail.list.items = this.Alphabetize(items);
              this.OpenDetailViewModal(selected);
            });
          } else {
            this.OpenDetailViewModal(selected);
          }
        });
        */
      },
      ObserveIntersections() {
        this.UnobserveIntersections();
        document.querySelectorAll('#salary-detail-view .toggle').forEach(toggle => {
          const observer = new IntersectionObserver(
            ([e]) => {e.target.toggleAttribute('stuck', e.intersectionRatio < 1)},
            {rootMargin: '-35px 0px 0px 0px', threshold: 1, root: document.querySelector('#salary-detail-view .scrollable')}
          );
          observer.observe(toggle);
          this.modal.detail.list.toggles.push(toggle);
          this.modal.detail.list.observers.push(observer);
        });
      },
      UnobserveIntersections() {
        this.modal.detail.list.toggles.map((toggle, i) => {
          this.modal.detail.list.observers[i].unobserve(toggle);
        });
        this.modal.detail.list.observers = [];
        this.modal.detail.list.toggles = [];
      },
      OpenDetailViewModal(selected) {
        //this.modal.detail.list.selected = this.modal.detail.list.items.filter(item => selected.includes(item.user_id));
        this.modal.detail.list.selected = this.list.items.filter(item => selected.includes(item.user_id) && item.punch_clock.length);
        this.modal.detail.tab.active = this.modal.detail.tabs[0];
        this.modal.detail.list.entries = selected.length;
        this.modal.detail.open = true;
        //this.UpdateAllNestedListHeights();
        this.$nextTick().then(this.ObserveIntersections);
      },
      async DownloadCsvReport() {
        const download = (csv, file_name) => {
          const a = document.createElement('a');
          a.href = 'data:text/plain;charset=utf-8,' + encodeURIComponent(csv);
          a.download = file_name || 'report.csv';
          a.style.display = 'none';
          document.body.append(a);
          a.click(); a.remove();
        }
        await this.GetSelected().then(selected => {
          let csv = [];
          const json = [];
          const items = Object.values(this.JSON(this.list.items).filter(item => selected.includes(item.user_id)));
          if (!this.modal.detail.open) {
            items.map(item => {
              const data = {};
              for (let key in item) {
                if (!/user_id|order_pack/.test(key)) {
                  if (key == 'punch_clock') {
                    item[key] = item[key].length;
                  } 
                  if (key == 'user_name') {
                    data['Employee name'] = Tool.TitleCase(item[key]);
                  } 
                  if (/pay|salary/ig.test(key)) {
                    item[key] = item[key].toFixed(2).replace('.', ',');
                  }
                  if (/time/ig.test(key)) {
                    const seconds = item[key] || 0;
                    item[key] = this.TimeFormat(seconds, true).replace(/\D\s/g, ':').slice(0, -1);
                    if (/hour/ig.test(key)) data['Hourly hours'] = Number(seconds / 3600).toFixed(2).replace('.', ',');
                    if (/commission/ig.test(key)) data['Commission hours'] = Number(seconds / 3600).toFixed(2).replace('.', ',');
                  }
                  data[Tool.Capitalize(key.replace(/_/g, ' '))] = item[key];
                }
              }
              delete data['User name'];
              json.push(data);
            });
            //console.log(console.log(JSON.parse(JSON.stringify(json))))
            json2csvAsync(json).then(download).catch(console.warn);
          } else { // detailed report - only users with punches
            items.filter(item => item.punch_clock.length).map(item => {
              const data = {
                social_security: [item.social_security],
                user_name: [this.TitleCase(item.user_name)],
                punch_in: [this.DateToISO(item.punch_clock.slice(item.unaccounted_punch_order_pack ? -2 : -1)[0].start_date, true)],
                punch_out: [this.DateToISO(item.punch_clock[0].end_date, true)],
                punch_time: [this.TimeFormat(item.hourly_time + item.commission_time, true).replace(/\D\s/g, ':').slice(0, -1)],
                order_count: [item.order_pack.length],
                salary_total: ['"' + item.salary_total.toFixed(2).replace('.', ',') + '"']
              }
              item.punch_clock.map(punch => {
                data.social_security.push('');
                data.user_name.push(this.Capitalize(punch.type));
                data.punch_in.push(this.DateToISO(punch.start_date, true));
                data.punch_out.push(this.DateToISO(punch.end_date, true));
                data.punch_time.push(punch.end_date ? this.TimeFormat(punch.total_seconds, true).replace(/\D\s/g, ':').slice(0, -1) : '');
                data.order_count.push(punch.order_pack.length);
                data.salary_total.push('"' + punch.salary_total.toFixed(2).replace('.', ',') + '"');
              });
              for (let key in data) {
                if (key == 'user_name') data['Employee name'] = data[key];
                data[this.Capitalize(key.replace(/_/g, ' '))] = data[key];
                delete data[key];
              }
              delete data['User name'];
              json.push(data);
            });
            const keys = Object.keys(json[0]);
            json.map((row, r) => {
              csv[r] = []; row[Object.keys(row)[0]].map(() => csv[r].push([]));
              keys.map((key, k) => row[key].map((col, c) => csv[r][c][k] = col));
            });
            csv.unshift(keys.join(';'));
            csv = csv.flat().join('\n').replace(/;/g, ',');
            download(csv);
          }
        });
      },
      SetActiveTab(e) {
        let tab = e.target.dataset.tab;
        if (!tab) tab = e.target.closest('[data-tab]').dataset.tab;
        this.modal.detail.tab.active = tab;
        //Object.values(this.modalTabsBody).map(ul => ul.scrollTop = 0);
      },
      ToggleListItem(e) {
        let element = e.target;
        if (!element.classList.contains('toggle')) {
          element = element.closest('.toggle');
          if (!element) return;
        }
        const collapsed = element.classList.contains('collapsed');
        const nested = element.nextSibling;
        if (collapsed) {
          this.SetNestedListHeight(nested);
        } else {
          nested.style.removeProperty('height');
        }
        clearTimeout(this.modal.detail.list.animate);
        nested.classList.add('animate');
        ['collapsed', 'expanded'].map(class_name => {
          element.classList.toggle(class_name);
        });
        this.modal.detail.list.animate = setTimeout(() => {
          nested.classList.remove('animate');
        }, 150);
      },
      SetNestedListHeight(element) {
        element.style.removeProperty('height');
        let clone = element.cloneNode(true);
        clone.classList.add('clone');
        element.parentElement.append(clone);
        let rect = clone.getBoundingClientRect();
        element.style.height = rect.height +'px';
        clone.remove();
      },
      UpdateAllNestedListHeights() {
        setTimeout(() => {
          const items = document.querySelectorAll('.expanded ~ .body');
          items.forEach(this.SetNestedListHeight);
        });
      },
    }
  }
</script>

<style lang="scss" scoped>
  @import '../../../assets/style/variables/colors';
  @import '../../../assets/style/variables/icons';

  .lists-wrapper__lists {
    //margin-top: 30px;
  }
  
  .padding-block {
    padding-block: 10px;
  }

  .grid-info {
    flex-wrap: nowrap;

    .grid-heading {
      flex-shrink: 0;
    }

    &.flex-center {
      align-items: center;
      //margin-bottom: 85px;

      .grid-actions {
        width: 100%;
        justify-content: flex-end;
        margin: 0 0 0 calc(10% + 20px);

        .select.period {
          width: 100%;
          max-width: 15em;
        }

        .date-range {
          width: 100%;

          .input {
            width: 100%;
          }
        }

        .button {
          height: 100%;
          min-width: 115px;
          flex-shrink: 0;
          background-color: $green;
        }

        > * + * {
          margin-left: 20px;
        }
      }
    }
  }

  .simple-list {
    border-top: 2px solid #d3d3d3;
    
    &__body {
      .list-cell {
        line-height: 1.2em;
      }
    }
    .user-name {
      width: 12%;
    }
    .pay-type {
      width: 14%;
    }
    .punch-clock {
      width: 7%;
    }
    .punch-type {
      width: 10%;
    }
    .punch-time {
      width: 15%;
    }
    .order-count {
      width: 7%;
    }
    .salary-type {
      width: 15%;
    }
    .salary-total {
      width: 8%;
    }
    
    *[class*=hourly] .padding-left {
      //padding-left: 3em;
      padding-left: 1em;
    }
    *[class*=commission] .padding-left {
      padding-left: 0.3em;
    }
  }

  .grid-footer {
    z-index: 2;
    bottom: -15px;
    display: flex;
    position: sticky;
    margin-bottom: -30px;
    background-color: $white;
    //border-top: 2px solid lightgrey;
    box-shadow: 0 -2px 0 0 lightgrey;
  }

  .button-container {
    width: 100%;
    padding: 0 0 30px;
    display: flex;
    position: relative;

    .entries {
      display: flex;
      flex-shrink: 0;
      padding: 0 10px;
      cursor: default;
      user-select: none;
      margin-right: 15px;
      border-radius: 4px;
      align-items: center;
      background-color: #f0f0f1;
    }

    button:first-of-type {
      margin-left: auto;
    }

    > * + * {
      margin-left: 15px;
    }
  }

  .button {
    user-select: none;
  }

  .modal {
    .button-container {
      padding-bottom: 0;
    }

    .work_report {
      &-user-name {
        width: 15%;
      }
      &-punch-state {
        width: 8%;
      }
      &-punch-clock {
        width: 8%;
      }
      &-hourly-pay-punch {
        width: 11%;
      }
      &-commission-punch {
        width: 12%;
      }
      &-hourly-pay-time {
        width: 10%;
      }
      &-commission-time {
        width: 11%;
      }
      &-work-time-total {
        width: 11%;
      }
      &-order-count {
        width: 5%;
      }
    }

    .punch_clock {
      &-user-name {
        width: 25%;
      }
      &-no-punch-out {
        width: 12%;
      }
      &-punch-in {
        width: 20%;
      }
      &-punch-out {
        width: 20%;
      }
      &-punch-time {
        width: 20%;
      }
    }

    .detail {
      &-user-name {
        width: 23%;
        display: flex;
        flex-direction: row;

        > * {
          flex: 1;

          & + * {
            margin-left: 10px;
          }
        }
      }
      &-punch-in {
        width: 20%;
      }
      &-punch-out {
        width: 20%;
      }
      &-punch-time {
        width: 13%;
      }
      &-order-count {
        width: 10%;
      }
      &-salary-total {
        width: 10%;
      }
    }
  }

  .twoinone {
    display: flex;
    line-height: 1.2em;
    flex-direction: row;

    &-col {
      b {
        font-weight: 600;
      }

      & + .twoinone-col {
        margin-left: 6px;
      }
    }
  }
</style>